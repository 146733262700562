export default [
  {
    header: 'База <Музыка>',
  },
  {
    title: 'Исполнители',
    route: 'artists',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Найти в Last.FM',
    route: 'artists-search',
    icon: 'SearchIcon',
    tagVariant: 'light-warning',
  },/*
  {
    title: 'Треки',
    route: 'tracks',
    icon: 'MusicIcon',
    tagVariant: 'light-warning',
  },*/
  {
    title: 'Списки',
    route: 'lists',
    icon: 'ListIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Темы',
    route: 'autotopics',
    icon: 'ColumnsIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Поиск Плейлистов',
    route: 'playlist-search',
    icon: 'SearchIcon',
    tagVariant: 'light-warning',
  },
  /*{
    title: 'Топ исполнителей',
    route: 'artists-top',
    icon: 'ArrowUpCircleIcon',
    tagVariant: 'light-warning',
  },*/
]
